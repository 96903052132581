model-viewer {
  display: table-cell;
  margin: auto;
  width: 100%;
  height: 100vh;
}

section {
  width: 100%;
  display: table;
  margin: 0;
  max-width: none;
  background-color: white;
  height: 100vh;
}
